import React from 'react';
import FormField, { FieldProps } from './FormField';

export type Props = Omit<React.ComponentProps<'input'>, 'type'> & Omit<FieldProps<'input'>, 'element' | 'elementProps'>;

// Simple wrapper around FormField for easier use
const Checkbox = React.forwardRef<any, Props>(({ fullWidth, error, label, variant, ...otherProps }: Props, ref) => {
  return (
    <FormField
      ref={ref}
      element="input"
      elementProps={{
        ...otherProps,
        type: 'checkbox',
      }}
      fullWidth={fullWidth}
      error={error}
      label={label}
      variant={variant}
    />
  );
});

export default Checkbox;
