export type ReqStatus = 'pending' | 'success' | 'err' | undefined;

export interface User {
  id: number;
  username: string;
  email: string;
  display_picture: string;
  guest_account: boolean;
  chargebee_customer_id: string;
  tracking_disabled?: boolean;
  reactions_enabled_default?: boolean;
  plan?: {
    id: number;
    name: string;
    api_name: string;
    default_game_type: string;
    educational: boolean;
  };

  // for user dropdown in navbar
  num_pending_invites: number;
  num_pending_requests: number;
  belongs_to_organization: boolean;
  num_pending_org_member_requests?: number;

  // Available from the login_state endpoint only
  first_name: string;
  last_name: string;
  creation_type?: string;
  date_joined: string;
  effective_plan_name?: string; // either the user's plan, or their organizations plan if they belong to one
  intercom_user_hash?: string;
  organization_name?: string;
  org_member_role_name?: string;
  api_key?: string; // used for making authenticated requests to the django API
}

export interface LibraryTag {
  id: number;
  name: string;
}

export interface LibraryGame {
  id: number;
  game_name: string;
  game_description: string;
  game_asset_path: string;
  game_cloning_share_key: string;
  library_tags: LibraryTag[];
}

// Initial user info loaded from server (needed for google tag manager and authenticating user)
export interface PreloadedUserInfo {
  id: number;
  marketingTrackingDisabled: boolean;
}

export interface ValidationRule {
  hasErr: boolean;
  errMsg: string;
}

export type ValidationResult =
  | {
      hasErr: true;
      errMsg: string;
    }
  | {
      hasErr: false;
    };

export enum ServerErrorCodes {
  GeneralErr = 1,
  DuplicateEmail = 5,
  DuplicateUsername = 6,
  InvalidEmail = 7,
  InvalidLogin = 8,
}

export type LandingPages =
  | 'snacknation'
  | 'Multiview_HR'
  | 'Multiview_Higher_Ed'
  | 'Multiview_Meetings'
  | 'Multiview_Community';
