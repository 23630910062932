import React from 'react';
import { useLocation } from 'react-router-dom';

import AccountFormLayout from '../../../components/layout/AccountFormLayout';
import Link from '../../../components/Link';
import routes from '../../../routes';
import SignUpForm from '../../../components/SignUpForm';
import SignUpStep1 from '../media/sign-up-step1.svg';
import SignUpStep2 from '../media/sign-up-step2.svg';
import SignUpStep3 from '../media/sign-up-step3.svg';
import styles from './SignUpContent.module.scss';
import Text from '../../../components/Text';

type Props = {
  isEdu?: boolean;
};

const SignUpContent = (props: Props) => {
  const location = useLocation();

  function renderSubtitle() {
    // Pass along next url param to login if routed there
    const searchParams = new URLSearchParams(location.search);
    const nextUrl = searchParams.get('next') || '';
    const loginHref = nextUrl ? `${routes.LOGIN.path}?next=${nextUrl}` : routes.LOGIN.path;

    if (props.isEdu) {
      return (
        <>
          <Text el="p" variant="paragraphMd">
            Create your own unforgettable Goosechase Experiences for your students and staff. Already signed up?{' '}
            <Link variant="underline" href={loginHref} useRouter>
              Log in
            </Link>
          </Text>
          <Text el="p" variant="paragraphMd" className={styles.notK12}>
            Not a K-12 Educator?{' '}
            <Link variant="underline" href={routes.SIGN_UP.path} useRouter>
              Continue with a recreational plan.
            </Link>
          </Text>
        </>
      );
    }

    return (
      <Text el="p" variant="paragraphMd">
        Hatch your own Goosechase Experiences and create unforgettable adventures for your community. Already signed up?{' '}
        <Link variant="underline" href={loginHref} useRouter>
          Log in
        </Link>
      </Text>
    );
  }

  return (
    <AccountFormLayout isEdu={props.isEdu}>
      <Text el="h1" variant="headingMd">
        Let&apos;s get started!
      </Text>
      {renderSubtitle()}
      <SignUpForm className={styles.signUpForm} isEdu={props.isEdu} />

      <AccountFormLayout.RightFixedContent
        className={props.isEdu ? styles.rightFixedContentEDU : styles.rightFixedContent}
        centered
      >
        <SignUpStep1 className={styles.howItWorksIcon} width="7.2rem" />
        <AccountFormLayout.RightFixedContent.Heading1>
          Create your Experience
        </AccountFormLayout.RightFixedContent.Heading1>
        <Text el="p" variant="paragraphMd" className={styles.hiwDesc}>
          {props.isEdu
            ? 'Get started with ready-to-go Missions or create your own'
            : 'Get started with ready-to-go Missions or create your own'}
        </Text>

        <SignUpStep2 className={styles.howItWorksIcon} width="7.2rem" />
        <AccountFormLayout.RightFixedContent.Heading1>
          {props.isEdu ? 'Invite students and staff' : 'Invite participants'}
        </AccountFormLayout.RightFixedContent.Heading1>
        <Text el="p" variant="paragraphMd" className={styles.hiwDesc}>
          {props.isEdu
            ? 'Participants join your experience with a unique join code'
            : 'Join from anywhere in the world, flying solo or in teams'}
        </Text>

        <SignUpStep3 className={styles.howItWorksIcon} width="7.2rem" />
        <AccountFormLayout.RightFixedContent.Heading1>
          {props.isEdu ? 'Watch the action' : 'Track the action'}
        </AccountFormLayout.RightFixedContent.Heading1>
        <Text el="p" variant="paragraphMd" className={styles.hiwDesc}>
          {props.isEdu
            ? 'All eyes on the activity feed and leaderboard'
            : 'All eyes on the activity feed and leaderboard'}
        </Text>
      </AccountFormLayout.RightFixedContent>
    </AccountFormLayout>
  );
};

export default SignUpContent;
