import React, { useContext, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import styles from './SignUpForm.module.scss';
import { ReqStatus, ServerErrorCodes, ValidationResult } from '../types';
import * as validation from '../services/validationService';
import Input from './form/Input';
import Button from './Button';
import Link from './Link';
import routes from '../routes';
import Alert from './Alert';
import { signUp } from '../apiClients/userClient';
import { appConfig } from '../../commonConfig';
import { UserDataContext } from '../App';
import Checkbox from './form/Checkbox';
import appConstants from '../appConstants';

const genErrText = 'An error occurred and your request was not submitted. Please try again.';

const SignUpForm = ({ className, isEdu }: { className?: string; isEdu?: boolean }) => {
  const { userData } = useContext(UserDataContext);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [marketingEmailsAccepted, setMarketingEmailsAccepted] = useState(appConstants.USER_COUNTRY_CODE === 'US'); // pre-check for US only
  const [errors, setErrors] = useState({
    general: '',
    email: '',
    username: '',
    password: '',
    marketingEmailsAccepted: '',
  });
  const [submitStatus, setSubmitStatus] = useState<ReqStatus>();

  const isLoading = submitStatus === 'pending';

  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newEmail = e.target.value;
    if (errors.email) {
      validateEmail(newEmail);
    }

    setEmail(newEmail);
  }

  function onUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newUsername = e.target.value;
    if (errors.username) {
      validateUsername(newUsername);
    }

    setUsername(newUsername);
  }

  function onPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newPassword = e.target.value;
    if (errors.password) {
      validatePassword(newPassword);
    }

    setPassword(newPassword);
  }

  const onMarketingEmailsAcceptedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarketingEmailsAccepted(e.target.checked);
  };

  function validateEmail(val: string): ValidationResult {
    const result = validation.validateEmail(val);

    setErrors((state) => ({
      ...state,
      email: result.hasErr ? result.errMsg : '',
    }));

    return result;
  }

  function validateUsername(val: string): ValidationResult {
    const result = { hasErr: !val, errMsg: 'Whoops! Username is required' };

    setErrors((state) => ({
      ...state,
      username: result.hasErr ? result.errMsg : '',
    }));

    return result;
  }

  function validatePassword(val: string): ValidationResult {
    const result = validation.validateNewPassword(val);

    setErrors((state) => ({
      ...state,
      password: result.hasErr ? result.errMsg : '',
    }));

    return result;
  }

  async function onFormSubmit(e: React.FormEvent) {
    e.preventDefault();

    const emailValidation = validateEmail(email);
    const usernameValidation = validateUsername(username);
    const passwordValidation = validatePassword(password);

    if (emailValidation.hasErr || usernameValidation.hasErr || passwordValidation.hasErr) {
      return;
    }

    setSubmitStatus('pending');
    setErrors((state) => ({
      ...state,
      general: '',
    }));

    const res = await signUp({
      email,
      username,
      password,
      isEdu: !!isEdu,
      landingPageVisited: userData.userMeta?.landingPageVisited,
      marketingEmailsAccepted,
    });
    if (res.err) {
      setSubmitStatus('err');

      if (res.err.response) {
        let errors = {
          general: '',
          email: '',
          username: '',
          password: '',
          marketingEmailsAccepted: '',
        };

        const errCode = res.err.response.data.result;
        switch (errCode) {
          case ServerErrorCodes.GeneralErr:
            errors.general = genErrText;
            break;
          case ServerErrorCodes.DuplicateEmail:
            errors.email = 'Whoops! That email is already taken';
            break;
          case ServerErrorCodes.DuplicateUsername:
            errors.username = 'Whoops! That username is already taken';
            break;
          case ServerErrorCodes.InvalidEmail:
            errors.email = 'Whoops! That email is invalid';
            break;
          default:
            errors.general = genErrText;
        }

        setErrors(errors);
      } else {
        setErrors((state) => ({
          ...state,
          general: genErrText,
        }));
      }

      return;
    }

    // Go to next page
    if (isEdu) {
      history.push(routes.EDU_ACTIVATE.path);
      return;
    }

    const queryStrParams = queryString.parse(window.location.search);
    let nextUrl = queryStrParams.next as string;

    if (!nextUrl) {
      nextUrl = `${appConfig.webAppBaseUrl}${res.data.next_url}`;
    }

    window.location.href = nextUrl;
  }

  return (
    <form noValidate onSubmit={onFormSubmit} className={className}>
      <div className={styles.inputWrapper}>
        <Input
          id="signUpEmail"
          type="email"
          autoComplete="email"
          fullWidth
          label="Email"
          value={email}
          onChange={onEmailChange}
          onBlur={() => validateEmail(email)}
          disabled={isLoading}
          error={errors.email}
        />
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id="signUpUsername"
          type="text"
          autoComplete="username"
          fullWidth
          label="Create a username"
          value={username}
          onChange={onUsernameChange}
          onBlur={() => validateUsername(username)}
          disabled={isLoading}
          error={errors.username}
        />
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id="signUpPassword"
          type="password"
          autoComplete="new-password"
          fullWidth
          label="Create a password"
          value={password}
          onChange={onPasswordChange}
          onBlur={() => validatePassword(password)}
          disabled={isLoading}
          error={errors.password}
        />
        <p className={styles.passwordRule}>Passwords must have at least {validation.PASSWORD_MIN_LEN} characters</p>
      </div>
      <div className={styles.inputWrapper}>
        <Checkbox
          id="signUpMarketingEmailConsent"
          label={`I agree to receive Goosechase news, updates and ideas.${
            appConstants.USER_COUNTRY_CODE === 'CA' ? ' You can unsubscribe at any time 🎉' : ''
          }`}
          checked={marketingEmailsAccepted}
          onChange={onMarketingEmailsAcceptedChange}
          disabled={isLoading}
        />
      </div>

      <div className={styles.submitWrapper}>
        {errors.general && (
          <Alert className={styles.generalErrMsg} type="error" title="Error">
            {errors.general}
          </Alert>
        )}
        <Button className={styles.submitBtn} type="submit" color="primary" disabled={isLoading}>
          Sign up
        </Button>
      </div>

      <p className={styles.disclaimer}>
        By signing up you accept our{' '}
        <Link variant="underline" href={routes.TERMS_OF_SERVICE.path} useRouter>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link variant="underline" href={routes.PRIVACY.path} useRouter>
          Privacy Policy
        </Link>
      </p>
    </form>
  );
};

export default SignUpForm;
