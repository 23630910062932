import React from 'react';
import Page from '../../components/Page';
import SignUpContent from './components/SignUpContent';

const EduSignUp = () => (
  <Page
    title="Sign Up for a K-12 Educator Account | Goosechase"
    description="Get started for free! Join thousands of other educators and create your own unforgettable Goosechase Experience for your students or staff."
    hideNavbar
    hideFooter
  >
    <SignUpContent isEdu />
  </Page>
);

export default EduSignUp;
